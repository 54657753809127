import React, { useEffect, useState } from "react";
import { FaDiscord, FaGithub } from "react-icons/fa";
import { FaBarsStaggered } from "react-icons/fa6";
import { Link, NavLink, useLocation } from "react-router-dom";
import AppMobileNav from "./AppMobileNav";
import Session from "supertokens-web-js/recipe/session";


import {
  appDownloads,
  appHome,
  appLogin,
  appPricing,
  sdkHome,
  appQA,
  appDev,
  appProduct,
  appFeature,
  appWhyUs,
  appPlayground
} from "../../../routes/routes";
import { logo } from "../../../assets";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { TbLivePhoto } from "react-icons/tb";

const AppHeader = () => {
  const location = useLocation();
  const [scrolling, setScrolling] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [doesSessionExistState, setDoesSessionExistState] = useState(false);
  const [openBanner, setOpenBanner] = useState(true);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (await Session.doesSessionExist()) {
        setDoesSessionExistState(true);
      }
    })();
  }, []);


  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let productHiglight = () => {
    if (location.pathname === appQA) {
      return true;
    }
    else if (location.pathname === appDev) {
      return true;
    }
    else if (location.pathname === appProduct) {
      return true;
    }
    else if (location.pathname === appFeature) {
      return true;
    }
    else if (location.pathname === appWhyUs) {
      return true;
    }
    else {
      return false
    }
  }

  return (
    <header
      id="navBar"
      className={`fixed inset-x-0 w-full ${scrolling ? " bg-opacity-90 backdrop-blur-lg" : "bg-transparent"
        } transition-all duration-300 ease-in-out z-[999]`}
    >
     {Boolean(openBanner) && <div className="text-center bg-primary-color/80 relative py-2 px-3">
        <p className="flex flex-col sm:flex-row items-center justify-center gap-1 sm:gap-2 font-primary font-bold text-[11px] sm:text-xs md:text-base">
         <span className="leading-tight"> Run and Test Mobile apps instantly with our new product</span>
          <NavLink
            to={'https://nativebridge.io/'} target='_blank'
           className="text-green-800 animate-pulse px-1 text-sm sm:text-base md:text-xl underline decoration-1 underline-offset-2"
          >
            {`NativeBridge.io !`}
          </NavLink>
          <span className="leading-tight">Waitlist today & Get Early Access!</span>
        </p>
        <button
          className="absolute top-1/2 -translate-y-1/2 right-1 sm:right-4 p-1"
          onClick={() => setOpenBanner(false)}
        >
          <IoIosCloseCircleOutline className="text-xl md:text-2xl" />
        </button>
      </div>}
      <div className="flex flex-row items-center justify-between sm:justify-around px-4 py-4 relative transition after:absolute after:inset-x-0 after:bottom-0 after:-z-10 after:h-px after:bg-gradient-to-r after:from-transparent after:via-zinc-500/20 sm:px-6">
        {/* Logo */}
        <div className="cursor-pointer relative  hover:scale-[1.1] duration-200 pb-2 max-w-40 md:max-w-none">
          <Link to={appHome} className="w-full">
            <img src="https://ik.imagekit.io/du5yq7zec/Landing%20Page/autoflowLogoWhite.png?updatedAt=1721131360109" alt="Logo" className="w-auto md:h-12" />
            <div class="badge badge-xs md:badge-md absolute bg-primary-color bottom-0 right-0 translate-x-[40%] translate-y-[40%] font-semibold h-fit pt-[1px] pb-[2px]">Studio</div>

          </Link>
        </div>

        {/* Navigation buttons */}
        <div className="hidden flex-shrink-0 flex-nowrap items-center justify-start rounded-full border border-zinc-300/10 p-1 sm:flex ml-1 md:ml-0">
          <div className="relative flex items-center font-display gap-x-1">
            <div className="dropdown dropdown-hover">
              <div tabIndex={0} role="button"
                className={` flex flex-shrink-0 rounded-full border border-transparent hover:border-primary-color/20 px-2 md:px-4 py-2 text-sm  transition hover:text-yellow-600/80 ${productHiglight()
                  ? "border-primary-color/20 text-yellow-600"
                  : "text-zinc-400"
                  }`}>
                Product
                <svg
                  className="-mr-1 ml-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <ul tabIndex={0} className="dropdown-content menu border border-zinc-300/10 bg-[#191919] shadow-xl rounded-box z-[1] w-80 px-2 pt-2 pb-0">
                <div className="flex justify-between px-1 pt-1" role="none">
                  <div className="flex flex-col gap-1 w-1/2">
                    <div className="text-secondary-color-light text-sm">About Product</div>
                    <ul className='list-disc list-outside -space-y-[2px]'>
                      <li className="text-lg text-secondary-color-light/50 group"> <Link className="p-0 group-hover:bg-transparent" to={appFeature}>
                        <div
                          className={`flex flex-shrink-0 text-[13px] transition group-hover:text-yellow-600/80 ${location.pathname === appFeature
                            ? "border-primary-color/20 text-yellow-600"
                            : "text-zinc-400"
                            }`}
                        >
                          Features
                        </div>
                      </Link></li>
                      <li className="text-lg text-secondary-color-light/50 group">
                        <Link to={'https://autoflow.featurebase.app/'} className="p-0 group-hover:bg-transparent" target='_blank'>
                          <div
                            className={`flex flex-shrink-0 border border-transparent text-[13px]  transition group-hover:text-yellow-600/80 ${location.pathname === appWhyUs
                              ? "border-primary-color/20 text-yellow-600"
                              : "text-zinc-400"
                              }`}
                          >
                            Roadmap
                          </div>
                        </Link>
                      </li>
                      <li className="text-lg text-secondary-color-light/50 group">
                        <Link to={sdkHome} className="p-0 group-hover:bg-transparent" target='_blank'>
                          <div
                            className={`flex flex-shrink-0 border border-transparent text-[13px]  transition group-hover:text-yellow-600/80 ${location.pathname === appWhyUs
                              ? "border-primary-color/20 text-yellow-600"
                              : "text-zinc-400"
                              }`}
                          >
                            SDK
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="flex flex-col gap-1 w-1/2">
                    <div className="text-left text-secondary-color-light text-bolder text-sm">Solutions</div>
                    <ul className='list-disc list-outside -space-y-[2px]'>
                      <li className="text-lg text-secondary-color-light/50 group">
                        <Link to={appQA} className="p-0 group-hover:bg-transparent">
                          <button
                            className={` flex flex-shrink-0 border border-transparent text-[13px]  transition group-hover:text-yellow-600/80 ${location.pathname === appQA
                              ? "border-primary-color/20 text-yellow-600"
                              : "text-zinc-400"
                              }`}
                          >
                            For QAs
                          </button>
                        </Link>
                      </li>
                      <li className="text-lg text-secondary-color-light/50 group">
                        <Link to={appDev} className="p-0 group-hover:bg-transparent">
                          <button
                            className={`flex flex-shrink-0 border border-transparent  text-[13px]  transition group-hover:text-yellow-600/80 ${location.pathname === appDev
                              ? "border-primary-color/20 text-yellow-600"
                              : "text-zinc-400"
                              }`}
                          >
                            For Developers
                          </button>
                        </Link>
                      </li>
                      <li className="text-lg text-secondary-color-light/50 group">
                        <Link to={appProduct} className="p-0 group-hover:bg-transparent">
                          <button
                            className={`flex flex-shrink-0 border border-transparent  text-[13px]  transition group-hover:text-yellow-600/80 ${location.pathname === appProduct
                              ? "border-primary-color/20 text-yellow-600"
                              : "text-zinc-400"
                              }`}
                          >
                            For Product Managers
                          </button>
                        </Link>
                      </li>
                    </ul>
                  </div>

                </div>
              </ul>
            </div>
            <Link to={appPlayground}>
              <button
                className={`relative z-10 flex flex-shrink-0 rounded-full border border-transparent hover:border-primary-color/20 px-2 md:px-4 py-2 text-sm  transition hover:text-yellow-600/80 ${location.pathname === appPlayground
                  ? "border-primary-color/20 text-yellow-600"
                  : "text-zinc-400"
                  }`}
                data-testid="playground-link"
              >
                Playground
              </button>
            </Link>

            <Link to={appPricing}>
              <button
                className={`relative z-10 flex flex-shrink-0 rounded-full border border-transparent hover:border-primary-color/20 px-2 md:px-4 py-2 text-sm  transition hover:text-yellow-600/80 ${location.pathname === appPricing
                  ? "border-primary-color/20 text-yellow-600"
                  : "text-zinc-400"
                  }`}
              >
                Pricing
              </button>
            </Link>
            <Link to={appDownloads}>
              <button
                className={`relative z-10 flex flex-shrink-0 rounded-full border border-transparent hover:border-primary-color/20 px-2 md:px-4 py-2 text-sm  transition hover:text-yellow-600/80 ${location.pathname === appPricing
                  ? "border-primary-color/20 text-yellow-600"
                  : "text-zinc-400"
                  }`}
              >
                Download
              </button>
            </Link>
            <button className={`relative z-10 flex flex-shrink-0 rounded-full border border-transparent hover:border-primary-color/20 px-2 md:px-4 py-2 text-sm  transition hover:text-yellow-600/80 text-zinc-400`}>
              <a href="https://docs.autoflow.tools" target="_blank">Docs</a>
            </button>
            <button className="relative z-10 flex flex-shrink-0  items-center rounded-full border border-transparent px-2 md:px-4 py-2 text-sm text-zinc-400 transition  cursor-not-allowed">
              <a href="https://blog.autoflow.tools" target="_blank">Blog</a>
            </button>
            
            <div className="flex overflow-hidden rounded-full">
              <Link to={appLogin} >
                <div className="flex flex-shrink-0 items-center justify-center border border-zinc-700 rounded-full bg-gradient-to-r from-zinc-600/30 via-zinc-700/30 to-zinc-800/30 px-2 md:px-4 py-2 text-zinc-50 text-sm backdrop-blur-md transition hover:from-zinc-600/50 hover:via-zinc-700/50 hover:to-zinc-800/50">
                  {doesSessionExistState ? "Dashboard" : "Sign In"}
                </div>
              </Link>
            </div>
          </div>
        </div>

        {/* Sign In button */}
        <div className="hidden sm:flex flex-row items-center">
          <a target="_blank" rel="noopener noreferrer" href="https://discord.com/invite/FHXJRkYqEQ">
            <FaDiscord className="text-xl text-white" />
          </a>
          <a
            href="https://github.com/AutoFlowLabs/autoflow"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-ghost text-white font-display font-bold px-2 md:px-4">
              <FaGithub className="text-lg" />
            </button>
          </a>
          <Link 
            target="_blank"
            to="https://dl.getautoflowstudio.com/book-a-demo"
          >
            <button
              className="relative inline-flex flex-shrink-0 items-center justify-center rounded-xl border border-zinc-300/10 bg-zinc-600/20 px-3 py-2 text-sm font-display font-normal transition text-secondary-color-light/80  hover:border-zinc-500/80 hover:bg-zinc-600/30
            "
            >
              Contact Sales
            </button>
          </Link>
        </div>
        <div className="flex sm:hidden">
          <button
            className="btn btn-ghost"
            onClick={() => {
              setOpenMenu(true);
            }}
          >
            <FaBarsStaggered className="text-2xl text-white" />
          </button>
          <AppMobileNav
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            location={location}
            productHiglight={productHiglight}
          />
        </div>
      </div>
    </header>
  );
};

export default AppHeader;
